import React from 'react';
import styled from 'styled-components';

import { SocialIcons, Text, Logo } from '@comicrelief/component-library';

const Foot = styled.footer`
  padding: 2rem 0;
  background-color: #2c0230;
`;

const Copy = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  p {
    width: 90%;
    margin-top: 4rem;
    line-height: 1.6;
  }
`;
const Footer = () => (
  <Foot>
    <SocialIcons campaign="sportrelief" target="self" />
    <Copy>
      <Logo
        rotate
        sizeSm="50px"
        sizeMd="60px"
        campaign="Comic Relief"
      />
      <Text tag="p" color="white">
        Sport Relief is an initiative of Comic Relief.
        Comic Relief is the trading name of Charity Projects,
        a registered charity in England and Wales (326568) and Scotland (SC039730),
        which is a company limited by guarantee registered in England and Wales (01806414).
        Registered address: 89 Albert Embankment, London SE1 7TP.
      </Text>
    </Copy>
  </Foot>
);

export default Footer;
