import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ThemeProvider, crTheme } from '@comicrelief/component-library';

import Footer from '../Footer/Footer';
import Header from '../Header/Header';

const Wraper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;

  [id^=single-msg]:first-child > div:nth-child(1) {
    height: auto;
  }

  [id^=single-msg]:nth-child(2) {
    padding: 16px;
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSize('xl')};
    line-height: ${({ theme }) => theme.fontSize('xl')};
  }

  @media ${({ theme }) => theme.breakpoint('small')} {
    min-height: 100vh;
    [id^=single-msg]:first-child {
      height: 100vh;
    }

    [id^=single-msg]:first-child div:nth-child(2) {
      min-height: auto;
      width: 45%;
      padding: 0;
      left: 4rem;
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSize('xxl')};
      line-height: ${({ theme }) => theme.fontSize('xxl')};
    }
  }

`;

const Layout = ({ children }) => (
  <ThemeProvider theme={crTheme}>
    <Wraper>
      <Header />
      <Content data-test="homepage">{children}</Content>
      <Footer />
    </Wraper>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
