import React from 'react';
import styled from 'styled-components';
import { Link } from '@comicrelief/component-library';

import Logo from '../../../static/assets/SR2020.svg';

const Wrapper = styled.div`
  padding: 10px 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    color: ${({ theme }) => theme.color('white')};
  }
`;

const LogoWrapper = styled.div`
  display: inline-block;
  width: 111px;
  height: 50px;
  @media (min-width: 768px) {
    width: 134px;
    height: 60px;
  }
`;
const Image = styled.img`
  object-fit: cover;
  width: auto;
  display: block;
  height: auto;
`;
const Header = () => (
  <Wrapper>
    <LogoWrapper>
      <Link href="/" title="Go to homepage">
        <Image src={Logo} alt="Sport Relief logo" />
      </Link>
    </LogoWrapper>
    <span>
      <Link
        title="Go to donate page"
        href="https://donation.sportrelief.com/?cartId=beat"
        type="button"
        color="blue_dark"
        target="self"
        rel="noopener"
      >
        Give now
      </Link>
    </span>
  </Wrapper>
);

export default Header;
